/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, LandingPageCta, LinkWithQuery, SideBySide, ResponsiveImg, TwoColCta, StateCta, QuestionAccordion, ThreeColCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!LinkWithQuery) _missingMdxReference("LinkWithQuery", true);
  if (!QuestionAccordion) _missingMdxReference("QuestionAccordion", true);
  if (!ResponsiveImg) _missingMdxReference("ResponsiveImg", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  if (!TwoColCta) _missingMdxReference("TwoColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/'], ['en', 'https://www.hear.com/hearing-aids/'], ['en-US', 'https://www.hear.com/hearing-aids/'], ['en-IN', 'https://www.hear.com/in/hearing-aids/'], ['en-ZA', 'https://www.hear.com/za/hearing-aid-types/'], ['en-MY', 'https://www.hear.com/my/hearing-aids/'], ['ko-KR', 'https://www.hear.com/kr/hearing-aid-shape'], ['en-CA', 'https://ca.hear.com/hearing-aids/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aids-for-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-for-you",
    "aria-label": "hearing aids for you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Aids for You"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hear.com’s team of experts is available to assist you if you've concluded that a hearing aid is the best option to assist you in your journey to better hearing. Browse our selection of modern hearing devices, discover our continually evolving technology, and locate the hearing aid that will assist you in reconnecting with the people and things you cherish. Fill out our quick questionnaire to contact a hearing expert."), "\n", React.createElement(LandingPageCta, {
    copy: "Check if you Qualify",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-hearing-aids-work",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-hearing-aids-work",
    "aria-label": "how hearing aids work permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How hearing aids work"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids are designed to receive sounds from the outside world, just like a healthy ear would do. They include microphones, which take sounds and convert them into vibrations and then into electrical impulses. However, at this stage, these signals are often too weak and need to be amplified before they can be converted into something wearers can actually hear. Amplifiers inside the hearing aid casing do the job, turning even very quiet noises into signals that can be turned into audible noise."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The final part of the hearing aid is the speaker, which takes these amplified signals and produces noise. The precise sound level can almost always be finely calibrated, depending on the environment and the wearer’s degree of hearing loss. Many hearing aids can also be calibrated to amplify high or low-frequency sounds, again, depending on the user’s needs."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-find-the-right-hearing-aid",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-find-the-right-hearing-aid",
    "aria-label": "how to find the right hearing aid permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to find the right hearing aid"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Medical-grade hearing aids are the only safe solution for hearing loss. The majority of wearers report an improved overall quality of life with hearing aids. In fact, many of them wish they had started wearing hearing aids sooner. Hearing aids strengthen relationships with family, friends and co-workers because you’re able to understand and fully participate in conversations again. Many studies show that hearing well can reduce stress, increase energy, and boost your mental alertness."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With all the advancements in technology, there’s a wide variety of hearing aid models for different lifestyles and levels of hearing loss. But modern hearing aids have one life-changing thing in common — their unbelievably small size. Both in-the-ear and behind-the-ear devices are often the size of coffee beans. In terms of ", React.createElement(LinkWithQuery, {
    to: "/hearing-aids/technology/"
  }, "hearing aid technology"), ", the latest devices are basically mini supercomputers that use advanced algorithms to process sounds and deliver crystal-clear sound. Finding the right hearing aid depends on a number of factors."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aid-styles",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aid-styles",
    "aria-label": "hearing aid styles permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Aid Styles"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are several different ", React.createElement(LinkWithQuery, {
    to: "/hearing-aids/types/"
  }, "types of hearing aids"), " available on the market today. Each type has its own unique features and benefits, and the right choice for you will depend on your specific needs and preferences."), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/ite-ha.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "ite-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ite-hearing-aids",
    "aria-label": "ite hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ITE Hearing Aids"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sitting directly inside the ear canal, ", React.createElement(LinkWithQuery, {
    to: "/hearing-aids/types/ite/"
  }, "in-the-ear hearing aids (ITE)"), " are virtually invisible. Due to their small size, ITE devices are best suited for those with mild to moderate hearing loss. Additionally, whether or not you can use an ITE device depends on the amount of room in your auditory canal. Our local ", React.createElement(LinkWithQuery, {
    to: "/about-us/network/"
  }, "Partner Provider"), " will conduct a comprehensive hearing evaluation in order to fit you with the right style of hearing aid.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/btw-ha.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "bte-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bte-hearing-aids",
    "aria-label": "bte hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "BTE Hearing Aids"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "High-performance and discreet ", React.createElement(LinkWithQuery, {
    to: "/hearing-aids/types/bte/"
  }, "behind-the-ear hearing aids (BTE)"), " are equipped with a sound tube with a customized earmold or external receiver that’s placed inside the ear canal. BTEs are suitable for mild to severe hearing loss. This type of hearing aid is generally available in various sizes and styles depending on your preferred performance and battery strength.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "finding-the-right-fit",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#finding-the-right-fit",
    "aria-label": "finding the right fit permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Finding The Right Fit"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids are not one-size-fits-all. You need to choose devices that meet your needs and fit seamlessly into your lifestyle. For example, a device with basic functions may be suitable for you depending on your daily activities. But if you’re someone who is very active, a premium technology level may provide you with the best listening experience. To help you determine which technology level is right for you, take a look at the chart below."), "\n", React.createElement(ResponsiveImg, {
    mobileSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aid-choices.jpg",
    desktopSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aid-choices-desk.jpg",
    alt: "Graph of different hearing aids usage"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aid-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aid-technology",
    "aria-label": "hearing aid technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Aid Technology"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "https://www.hear.com/hearing-aids/technology/",
    className: "c-md-a"
  }, "Hearing aids technology"), " has advanced significantly in recent years, offering a wide range of features and capabilities to help people with hearing loss."), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ix-hearing-aids-box-black.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "premium-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#premium-technology",
    "aria-label": "premium technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Premium Technology"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re looking for hearing aids with all the bells and whistles, then look no further than Premium technology. These devices offer more advanced features than Basic and Mid-Range levels, making them the best solution for both active and sedentary lifestyles. Whether you spend your days indoors or outdoors, you’ll experience the most natural sound quality. They deliver HD sound quality, directional microphones, acoustic motion sensors, digital noise/feedback reduction, moisture resistance and much more. Many hearing aids are also rechargeable, so you won’t have to fumble with tiny batteries. Best of all, Premium-level technology often comes equipped with ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth"), " and smartphone connectivity for settings control and to wirelessly stream music, phone calls and more directly to your hearing aids.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/livio_al.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "advanced-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#advanced-technology",
    "aria-label": "advanced technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Advanced Technology"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids with advanced technology are great for people who live a more active lifestyle. These devices provide good sound quality, even in complex listening situations. Examples of places where you can benefit from wearing this level include large auditoriums, family get-togethers, sporting events and restaurants. Advanced technology gives you the ability to change listening programs and improve listening comfort in any setting.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In addition, many of the features are automated, which removes the constant need to manually adjust the settings. Some of these features include adaptive directional microphones and automatic noise reduction."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "essential-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#essential-technology",
    "aria-label": "essential technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Essential Technology"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Essential level technology is a good solution for individuals who spend a lot of time at home and enjoy having one-on-one conversations. Devices with this level of technology provide good amplification and feedback cancellation in quiet environments. Their basic features allow you to hear the TV at normal volume and understand your conversation partner clearly in small group settings. We do, however, recommend getting the highest level of technology you can afford, so you can have access to more features for the best hearing experience."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aid-brands",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aid-brands",
    "aria-label": "hearing aid brands permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Aid Brands"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When considering hearing aids, it's important to explore ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/",
    className: "c-md-a"
  }, "reputable brands"), " that are known for their quality and innovation. There are several well-established brands in the market, each offering a range of devices to cater to different hearing needs. These brands invest in research and development to provide advanced technology and user-friendly designs. It's advisable to consult with a hearing care professional who can guide you in choosing the brand that aligns with your specific requirements."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aid-prices",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aid-prices",
    "aria-label": "hearing aid prices permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Aid Prices"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/prices/",
    className: "c-md-a"
  }, "Hearing aid prices"), " can vary based on several factors, including the brand, features, and technology level. Generally, there is a range of price options available to accommodate different budgets. Entry-level hearing aids typically fall on the more affordable end, while premium devices with advanced features tend to have higher price points. It's important to consider the overall value and benefits of the hearing aids when making a decision. Additionally, it's worth exploring if insurance coverage or assistance programs are available to help offset the costs. Working with a hearing care professional can provide valuable insights into pricing options and help you find a suitable solution within your budget."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "adjusting-to-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#adjusting-to-hearing-aids",
    "aria-label": "adjusting to hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Adjusting to hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Adjusting to hearing aids can take some time, but with patience and persistence, you can make the process smoother. Here are some tips to help you adapt to your new hearing aids:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Give Yourself Time: Understand that it takes time for your brain to adjust to the new sounds. Be patient and allow yourself the necessary time to adapt."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Start in a Quiet Environment: Begin by wearing your hearing aids in a quiet setting to help your brain acclimate to the new sounds gradually."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Gradual Increase in Wear Time: Start by wearing your hearing aids for a few hours each day, gradually increasing the time as you become more comfortable. This can help prevent overwhelm."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Practice Listening: Engage in activities that involve listening, such as conversations with friends and family, listening to music, or watching TV. This will help your brain adjust to various sounds."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Regular Follow-ups with Audiologist: Schedule regular follow-up appointments with your audiologist to make necessary adjustments to the hearing aids based on your experiences and feedback."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Learn about Maintenance: Familiarize yourself with the proper care and maintenance of your hearing aids. Clean them regularly, and make sure to change the batteries or charge them as needed."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Stay Positive: Focus on the improvements your hearing aids bring rather than the initial challenges. A positive attitude can make the adjustment period more manageable."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "our-best-hearing-aids-recommendations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#our-best-hearing-aids-recommendations",
    "aria-label": "our best hearing aids recommendations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Our Best Hearing Aids Recommendations"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Two of the best hearing aids on the market today include the Horizon Go IX and the Horizon Mini IX, both which are part of the ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/",
    className: "c-md-a"
  }, "exclusive Horizon IX line of hearing aids by hear.com"), ". No matter if you have mild or severe hearing loss, one of these state-of-the-art devices is sure to work for you."), "\n", React.createElement(TwoColCta, {
    imgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ix-hearing-aids.png",
    copy1: "Horizon Go IX",
    url1: "/hearing-aids/horizon/go-ix/",
    imgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/mini-ix-hearing-aids.png",
    copy2: "Horizon Mini IX",
    url2: "/hearing-aids/horizon/mini-ix/"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "we-make-getting-hearing-aids-easy",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#we-make-getting-hearing-aids-easy",
    "aria-label": "we make getting hearing aids easy permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "We Make Getting Hearing Aids Easy"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We make it super easy, convenient and pain-free for you to improve your hearing. We’ll take the lead and guide you through everything from scheduling your appointments to making sure you find the perfect devices for your lifestyle, budget and hearing needs. Just click here to see how it works!"), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "frequently-asked-questions",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#frequently-asked-questions",
    "aria-label": "frequently asked questions permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Frequently asked questions"), "\n", React.createElement(QuestionAccordion, {
    question: "How much do hearing aids cost?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aid prices vary based on several factors, including the brand, features, and technology level. Generally, there is a range of price options available to accommodate different budgets. Entry-level hearing aids typically fall on the more affordable end, while premium devices with advanced features tend to have higher price points. It's essential to consider the overall value and benefits of the hearing aids when making a decision. Working with a hearing care professional can provide valuable insights into pricing options and help you find a suitable solution within your budget."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn more about ", React.createElement(_components.a, {
    href: "/hearing-aids/prices/",
    className: "c-md-a"
  }, "hearing aid prices"), ".")), "\n", React.createElement(QuestionAccordion, {
    question: "Where can I buy hearing aids"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you contact a hearing professional from hear.com, we will evaluate your condition and determine the right hearing aids for you. We focus on quality and give our customers life-changing solutions to their hearing problems."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Please fill out ", React.createElement(_components.a, {
    href: "https://www.hear.com/d/full_page_questionnaire/",
    className: "c-md-a"
  }, "this questionnaire"), " to check if you qualify for a our 45-day no-risk trial!")), "\n", React.createElement(QuestionAccordion, {
    question: "Will I need hearing aids for both ears?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Years of medical research and user experience show that two hearing aids are almost always the recommended course of action for treating bilateral hearing loss. Find out why!"), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/resources/hearing-aids/do-you-really-need-two-hearing-aids/",
    className: "c-md-a"
  }, "Read more"), ".")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Hearing Loss",
    ContentCopy2: "Explore our Resources on Hearing Loss",
    Url2: "/hearing-loss/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/resources/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
